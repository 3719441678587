<template>
  <div class="employeeLineWrap" :class="(employee.prepared && employee.prepared.typeString) ? employee.prepared.typeString : ''">
    <div class="lineitem first l-checkbox">
    <div class="checkbox">
      <input type="checkbox"
             :id="'id_'+employee.id"
             :value="employee.id"
             v-if="employeeList && employeeList.selected"
             v-model="employeeList.selected[employee.id]"/>
      <label :for="'id_'+employee.id"></label>
    </div>
    </div>
    <div class="lineitem l-name" @click="setProduct(employee)" >
      {{ employee.e_name }}
    </div>
    <div class="lineitem l-category">
      {{ employee.category}}
    </div>
    <div class="lineitem l-id">
      {{ employee.employee.id}}
    </div>
    <div class="lineitem l-email">
      {{ employee.employee.e_email}}
    </div>
    <div class="lineitem l-phone">
      <div class="btn-group" v-if="employee.employee.phone" tooltip="Telefon" position="right">
        <button class="copyClip" v-if="employee.employee.phone"
                @click="$store.dispatch('copyToClipboard',employee.employee.phone)"><i class="fa-duotone fa-phone"></i> {{ employee.employee.phone }}
        </button>
        <callnow :phone="employee.employee.phone" />
      </div>
    </div>
    <div class="lineitem l-phone">

    </div>
    <div class="lineitem multiFa">
      asfaf
      <template v-if="employee._2fa === 'true'">
        <span tooltip="2fa-Aktiv" position="right">
        <i class="fa-duotone fa-shield-check"></i></span>
      </template>
      <template v-else>
         <span tooltip="2fa-Inaktive" position="right">
        <i class="fa-duotone fa-shield" ></i></span>
      </template>
    </div>
    <div class="lineitem l-issues">
      <template v-if="employee.use_in_issues === 'yes'">
        <span tooltip="Ticket-System aktiv" position="right">
        <i class="fa-duotone fa-clipboard-list"></i></span>
      </template>
      <template v-else>
         <span tooltip="2fa-Inaktive" position="right">
        <i class="fa-duotone fa-clipboard-list-check" ></i></span>
      </template>
    </div>
    <div class="lineitem last ">
      <template v-if="employeeActive && employeeActive.id === employee.id">
        <i class="fa-duotone fa-x" @click="$store.commit('closeEmployee')"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up" @click="setProduct(employee)" ></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import $ from "jquery";
import employee from "@/routing/core/Config/Employee.vue";
import callnow from "@/routing/plugins/callnow.vue";

export default {
  name: "Headline",
  data() {
    return {
      modalContent: null,
      addDocNotice: false,
      orderConfirmation: false,
      orderCancelation: false,
      sendContent: false,
      sendContentTitle: '',
      sendContentText: '',
      orderConfirmationText: '',
      toast: useToast(),
      address: {},
      notice: {
        document_id: null,
        notice_message: ''
      },
      storno:{
        title: '',
        message:'',
        reason: ''
      }
    }
  },
  props: {
    employee: Object,
  },
  components: {
    Editor2, callnow
  },
  computed: {
    employee() {
      return employee
    },
    ...mapGetters(["employeeActive", "getEmployees", "employeeList", "getConfigs"])
  },
  methods: {
    prepareStorno(document){
      this.orderCancelation = true;
      this.storno.title = document.prepared.custom_fields.document_sending.subject;
      this.storno.message = document.prepared.custom_fields.document_sending.body;
    },
    setProduct(employee) {
      let VueInner = this;
      this.activeEmployee = employee.id;
      this.$store.dispatch("setActiveProduct", product.id).then(function () {
        VueInner.$router.push({name: "ProductDetails", params: {id: product.id}});
        VueInner.scrollToTop();
      });
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    prepareSend(document){
      this.sendContent = true;
      this.sendContentTitle = document.prepared.custom_fields.document_sending.subject
      this.sendContentText = document.prepared.custom_fields.document_sending.body
    },
    saveContentCancel(){
      this.putAction('cancellation',{title: this.storno.title, message: this.storno.message, reason: this.storno.reason});
      this.orderCancelation = false;
    },
    saveContentText(){
      this.postAction('sendPDF',{title: this.sendContentTitle, message: this.sendContentText});
      this.sendContent = false;
    },
    saveConfirmOrder(){
      this.putAction('createOrder', {message: this.orderConfirmationText});
      this.orderConfirmation = false;
    },
    postAction(action, payload) {
      if(payload === undefined) {
        payload = {};
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.getters.getApiUrl + "employees/" + this.employee.id + "/" + action, payload, {headers}).then((r) => {
        this.$store.dispatch("employeesFilter");
        this.toast.success("Vorgang erfolgreich");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    putAction(action, payload) {
      if(payload === undefined) {
        payload = {};
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.getters.getApiUrl + "employees/" + this.employee.id + "/" + action, payload, {headers}).then((r) => {
        this.$store.dispatch("employeesFilter");
        this.toast.success("Vorgang erfolgreich");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    previewAction(action) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "employees/" + this.employee.id + "/" + action, {headers}).then((r) => {
        // open Modal with PDF Base64
        this.modalContent = r.data.result.data;
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    makeClassSave(str) {
      console.log(str);
      if (str) {
        return str.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      }
    },
    postNotice() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {message: this.notice.notice_message}
      axios.post(this.$store.state.apiUrl + "employee/" + this.notice.employee_id + "/notice/", postContent, {headers}).then((r) => {
        this.addDocNotice = false;
        this.notice.notice_message = '';
        this.notice.employee_id = null;
        this.toast.success('Notiz wurde erfolgreich gespeichert');
      }).catch((e) => {
        this.toast.error(e.response.data.result.message);
      });
    },
  },
}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}
</style>
