<template>
  <div class="card">
    <div class="card-body" v-if="mails && mails.combined && mails.combined.length > 0">
      <div class="mail-item" v-for="mail in mails.combined" v-bind:key="mail.id">
        <template v-if="mail._type === 'mail'">
          <div class="row mb-3 pb-3 borderRow">
            <!--{{ mail }}-->
            <div class="col-9 justify-content-center align-self-center">
              <p class="mb-0 text-sm font-weight-bold"><img style="max-width: 40px"
                                                            :src="$filters.employeeIcon(getEmployees, mail.employee_created, 'u_id')"
                                                            class="sketchAvatar">
                {{ $filters.formatDateTime(mail.created_time) }}
                <template v-if="mail.todo_type === 'document'"><span tooltip="Dokument"><i
                  class="fa-duotone fa-file"></i></span></template>
                <template v-if="mail.todo_type === 'customer'"><span tooltip="E-Mail an Kunde"><i class="fa-duotone fa-user"></i></span>
                </template>
              </p>
              <p class="mb-0 text-xs">{{ mail.payload.subject }}</p>
            </div>
            <div class="col-3 text-end  justify-content-center align-self-center">
              <!-- if attachments lenght print icon -->
              <span v-if="mail.payload.attachments.length > 0" style="margin-right: 6px;">
              <i class="fa-duotone fa-paperclip"></i> {{ mail.payload.attachments.length }}
            </span>
              <button type="button" class="btn btn-outline-primary btn-sm" @click="setMail(mail);"
                      :tooltip="this.$t('globals.view')" :position="'left'"><i
                class="fa-duotone fa-eye"></i>
              </button>
            </div>
          </div>
        </template>
        <template v-else-if="mail._type === 'file'">
          <div class="row mb-3 pb-3 borderRow">
            <div class="col-9  justify-content-center align-self-center">
              <p class="mb-0 text-sm font-weight-bold">
                {{ $filters.formatDateTime(mail.uploaded_on) }} <span tooltip="Datei"><i class="fa-duotone fa-file"></i></span></p>
              <p class="mb-0 text-xs">{{ mail.title }}</p>
            </div>
            <div class="col-3">
              <div class="btn btn-outline-primary btn-sm float-end" @click="downloadFile(mail.customer_id, mail.id)"
                   :tooltip="this.$t('globals.download')" :position="'left'"><i class="fa-duotone fa-download"></i>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="mail.data && mail.data._type === 'archive'">

          <div class="row mb-3 pb-3 borderRow">
            <div class="col-9">
              <p class="mb-0 text-sm font-weight-bold"><img style="max-width: 40px"
                                                            v-if="getEmployees && getEmployees.length > 0 && mail.data.employee_id"
                                                            :src="$filters.employeeIcon(getEmployees, mail.data.employee_id, 'id')"
                                                            class="sketchAvatar">
                {{ $filters.formatDateTime(mail.data.crm_created_date) }} <span tooltip="Archivierung"><i class="fa-duotone fa-archive"></i></span>
              </p>
              <p class="mb-0 text-xs">{{ mail.data.title }}</p>
            </div>
            <div class="col-3">
              <button type="button" class="btn btn-outline-primary btn-sm float-end" @click="setMail(mail.data);"
                      :tooltip="this.$t('globals.view')" :position="'left'"><i
                class="fa-duotone fa-eye"></i>
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="card-body" v-else><p class="noItemsFound">{{ $t("activity.noEntriesFound") }}</p></div>
  </div>
  <Modal v-if="activeMail">
    <div class="modal  show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" v-if="activeMail.payload && activeMail.payload.subject">{{ activeMail.payload.subject }}</h5>
            <h5 v-else>
              <template v-if="activeMail.title">{{activeMail.title}}</template>
            </h5>
            <button type="button" class="btn-close" @click="resetMail" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="activeMail.payload && activeMail.payload.body" v-html="activeMail.payload.body"></div>
            <div v-else v-html="activeMail.description"></div>
            <div class="attachments" v-if="activeMail.payload && activeMail.payload.attachments && activeMail.payload.attachments.length">
              <h5><i class="fa-duotone fa-paperclip"></i> {{ $t("globals.attachments") }}</h5>
              <ul>
                <li v-for="(att, inn) in activeMail.payload.attachments">
                  <button class="btn" @click="openAttachmentFromMail(activeMail.id, inn)"><i
                    class="fa-duotone fa-paperclip"></i>{{ $t("globals.data") }} {{ inn + 1 }}
                  </button>
                </li>
              </ul>
            </div>
            <template v-else>
              <template v-if="activeMail.attachments && activeMail.attachments.length">
                <h5><i class="fa-duotone fa-paperclip"></i> {{ $t("globals.attachments") }}</h5>
                <ul>
                  <li v-for="(att, inn) in activeMail.attachments">
                    <button class="btn" @click="openAttachmentFromMail(activeMail.id, inn)"><i
                      class="fa-duotone fa-paperclip"></i>{{ $t("globals.data") }} {{ inn + 1 }}
                    </button>
                  </li>
                </ul>
              </template>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" @click="resetMail"><i
              class="fa-duotone fa-xmark-circle"></i>{{ $t("globals.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "CustomerMails",
  props: {
    customerId: {}
  },
  data() {
    return {
      mails: [],
      file: '',
      activeMail: null,
    };
  },
  computed: {
    ...mapGetters(["getPlugins", "getEmployees", "customerActive", "modalsList"]),
  },
  methods: {
    openAttachmentFromMail(mailId, key) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/mails/" + mailId + "/attachment/" + key, {headers}).then((r) => {
        const file = r.data.result.data;
        const linkSource = 'data:' + file.mimeType + ';base64,' + file.file;
        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', file.filename);
        window.document.body.appendChild(a);
        a.click();
      });
    },
    getMails() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/mails", {headers}).then((r) => {
        this.mails = r.data.result.data;
        this.loading = false;
      });
    },
    setMail(mail) {
      this.activeMail = mail;
    },
    resetMail() {
      this.activeMail = null;
    },
    downloadFile(customerId, attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          let file = response.data.result.data;
          this.file = file;
          const linkSource = 'data:' + file.contentType + ';base64,' + file.content;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', file.title);
          window.document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getMails();
  },
  watch: {
    customerId: function () {
      this.getMails();
    }
  }
};
</script>
<style lang="scss">
.modal {
  z-index: 10000 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}
</style>
