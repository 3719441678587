<template>
  <!-- check if filter.source is array -->
  <template v-if="getPlugins && filter && filter.name &&  (filter.plugin === undefined || (getPlugins.infos && getPlugins.infos[filter.plugin]))">
    <label v-if="filter && filter.name"><small style="font-size: 12px;">{{getTranslation(filter.name)}}</small></label>
    <div class="input-group"  >
      <input type="number" v-model="$store.state.filters[type][filter.name].from" class="form-control small" :placeholder="$t('globals.from')" @change="$store.dispatch(type+'Filter');" >
      <span class="input-group-text">-</span>
      <input type="number" class="form-control small" :placeholder="$t('globals.till')"  v-model="$store.state.filters[type][filter.name].till" @change="$store.dispatch(type+'Filter');" >
    </div>

  </template>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
  name: "fromtill",
  data() {
    return {
      customers: "",
      options: [],
      overrides: { },
    };
  },
  components: {
  },
  methods: {
    refreshList() {
      this.$store.state.lists[this.type].offset = 0;
      this.$store.dispatch(this.type + 'Filter');
    },
    clearSelection() {
      this.$store.state.filters[this.type][this.filter.name].from = '';
      this.$store.state.filters[this.type][this.filter.name].till = '';
      this.refreshList();
    },
    getTranslation(filterKey) {
      if(this.filter.title){
        return this.filter.title;
      } else {
      const overrideKey = this.overrides[filterKey];
      if (overrideKey) {
        return overrideKey;
      }
      // Fallback to the default translation
      return this.$t('filters.' + filterKey);}
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getConfigs", "getPlugins"]),
  },
  created() {
    console.log(this.type);
    console.log(this.filter);

  },
}
</script>

<script setup>
</script>
