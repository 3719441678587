<template>
  <div class="card" :class="(activeEditor !== 'base') ? 'darken' : ''">
    <div class="card-body" v-if="!activeRequestType">
      <div class="row">
        <div class="col-12">

          <div class="filterBar">
            <div class="years  mb-3">
              <button class="btn me-2" :class="(filter.year === null) ? 'btn-secondary' : 'btn-outline-secondary'" @click="filter.year = null; filterTypes();">Alle Jahre</button>
              <button class="btn me-2" :class="(filter.year === year) ? 'btn-secondary' : 'btn-outline-secondary'" v-for="year in filterYears" @click="filter.year = year; filterTypes()">{{year}}</button>
            </div>
            <div class="grants">

              <button class="btn me-2" :class="(filter.grant === null) ? 'btn-secondary' : 'btn-outline-secondary'" @click="filter.grant = null; filterTypes();">Alle Arten</button>
              <button class="btn me-2" :class="(filter.grant === grant) ? 'btn-secondary' : 'btn-outline-secondary'" v-for="grant in filterGrants" @click="filter.grant = grant; filterTypes()">{{grant}}</button>

            </div>
          </div>

          <div class="singleRequest" v-for="request in filteredTypes" :key="request.id">
            <div class="pluginWrap">
              <div class="plugin-item">

                <div class="lineitem l-prio">
                  <span class="roundBadge" :class="renderGradeClass(request.name)" :style="'background-color:'+request.color+';'"
                        :tooltip="request.name">{{ renderGradeName(request.name) }}</span>
                </div>
                <div class="lineitem titlelong"><i class="fa-duotone fa-calendar-clock"></i>
                  {{ $filters.formatDateTime(request.published_from) }} - <i
                    class="fa-duotone fa-calendar-exclamation"></i>
                  {{ $filters.formatDateTime(request.published_to) }}
                </div>
                <div class="lineitem l-state">
                  <span v-html="renderState(request.state)" :tooltip="request.state" position="right"></span>
                </div>
                <div class="lineitem  titlelong" style="padding-left: 48px;" v-if="request.choosePrepare">
                  <span style="display: inline-block; margin-right: 12px;">{{request.choosePrepare.counters.complete}} Bewerber</span>
                  <span style="display: inline-block; margin-right: 12px;">{{request.choosePrepare.counters.accepted}} Akzeptiert</span>
                  <span style="display: inline-block; margin-right: 12px;">{{request.choosePrepare.counters.declined}} Andere</span>

                </div>
                <div class="lineitem ml-auto">
                  <div class="btn-group">
                    <button @click="activeRequestType = request" tooltip="Editieren" position="left"
                            class="btn btn-outline-primary btn-xs"><i class="fa-duotone fa-edit"></i></button>
                    <button @click="copyRequest(request.id, 'default')" tooltip="Kopieren" position="left"
                            class="btn btn-xs btn-outline-success">
                      <i class="fa-duotone fa-copy"></i></button>
                    <button @click="copyRequest(request.id, 'accepted')" tooltip="Kopieren mit akzeptierten Bewerbern" position="left"
                            class="btn btn-xs btn-outline-warning">
                      <i class="fa-duotone fa-copy"></i></button>
                    <button @click="copyRequest(request.id, 'all')" tooltip="Kopieren mit allen Bewerbern" position="left"
                            class="btn btn-xs btn-outline-danger">
                      <i class="fa-duotone fa-copy"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="row">
        <div class="col-3">
          <h4>{{ activeRequestType.name }}</h4>
        </div>
        <div class="col-5">
          <div class="row">
            <div class="col-4">
              <button class="btn btn-outline-secondary me-3 w-100 btn-sm" :class="{'btn-success': (activeEditor === 'base')}"
                      @click="activeEditor = 'base'">Daten
              </button>
            </div>
            <div class="col-4">
              <button class="btn btn-outline-secondary me-3 w-100 btn-sm" :class="{'btn-success': (activeEditor === 'tasks')}"
                      @click="activeEditor = 'tasks'">Aufgaben
              </button>
            </div>
            <div class="col-4" v-if="activeRequestType.use_questions === 'yes'">
              <button class="btn btn-outline-secondary me-3 w-100 btn-sm" :class="{'btn-success': (activeEditor === 'questions')}"
                      @click="activeEditor = 'questions'">Fragen
              </button>
            </div>
          </div>
        </div>
        <div class="col-4">
          <button @click="activeRequestType = null" class="btn btn-outline-danger btn-sm float-end"><i class="fa-duotone fa-circle-xmark"></i> Zur Übersicht</button>
          <button @click="saveRequest()" v-if="activeEditor === 'base'" class="btn btn-outline-primary me-2 float-end btn-sm"><i class="fa-duotone fa-save"></i>
            Speichern
          </button>
        </div>
      </div>
      <template v-if="activeEditor === 'tasks'">
        <TaskTypes :requestId="activeRequestType.id"/>
      </template>
      <template v-if="activeEditor === 'questions'">
        <CalcTypes :requestId="activeRequestType.id"/>
      </template>
      <template v-if="activeEditor === 'base'">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" v-model="activeRequestType.name" placeholder="Name">
            </div>
          </div>
          <div class="col-4">
            <div class="form-group  mb-4">
              <label>Aufnahmeprüfverfahren</label>
              <select class="form-select" name="" id="" v-model="activeRequestType.use_questions">
                <option value="yes">Ja</option>
                <option value="no">Nein</option>
              </select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group  mb-4">
              <label>Sonderantrag</label>
              <select class="form-select" name="" id="" v-model="activeRequestType.use_special_order">
                <option value="yes">Ja</option>
                <option value="no">Nein</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" v-if="activeRequestType.use_special_order === 'yes'">
          <div class="col-6">
            <div class="form-group">
              <label>Sonderantrag Titel</label>
              <input type="text" class="form-control" id="special_order_headline" v-model="activeRequestType.special_order_headline">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group" v-if="!activeRequestType.special_order_mime || activeRequestType.special_order_mime === ''">
              <label>Sonderantrag Datei</label>
              <input type="file" ref="fileInput" class="form-control" @change="onImageChange" accept="images/*"/>
            </div>
            <div class="" v-else>
              <label>Sonderantrag Datei</label>
              <br>
              <!-- Download pdf -->
              <a :href="'data:'+activeRequestType.special_order_mime+';base64,'+activeRequestType.special_order_src" download="special_order.pdf" class="btn btn-outline-primary me-2 btn-sm">Download</a>
              <button class="btn btn-outline-danger btn-sm" @click="activeRequestType.special_order_src = ''; activeRequestType.special_order_mime = ''">Löschen</button>

          </div>
          </div>
        </div>
        <div class="row" v-if="activeRequestType.use_special_order === 'yes'">
          <div class="col-12">
            <div class="form-group">
              <label>Sonderantrag Erklärung</label>
              <Editor2 v-model="activeRequestType.special_order_body" :content="activeRequestType.special_order_body"/>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-4">
            <div class="form-group">
              <label>Registrierung von</label>
              <input type="datetime-local" class="form-control" id="published_from"
                     v-model="activeRequestType.published_from">
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label>Registrierung bis</label>
              <input type="datetime-local" class="form-control" id="published_to"
                     v-model="activeRequestType.published_to">
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label>Poststempel bis</label>
              <input type="date" class="form-control" id="postmark_to"
                     v-model="activeRequestType.postmark">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group  mb-4">
              <label>Vorlage für Dankesnachricht</label>
              <select class="form-select" name="" id="" v-model="activeRequestType.draft_thanks">
                <option :key="draft.id" :value="draft.id" v-for="draft in drafts">
                  {{ draft.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group  mb-4">
              <label>Vorlage für Ende</label>
              <select class="form-select" name="" id="" v-model="activeRequestType.draft_end">
                <option :key="draft.id" :value="draft.id" v-for="draft in drafts">
                  {{ draft.title }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group  mb-4">
              <label>Vorlage für 3 Tage vor Ablauf</label>
              <select class="form-select" name="" id="" v-model="activeRequestType.draft_prelast">
                <option :key="draft.id" :value="draft.id" v-for="draft in drafts">
                  {{ draft.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group  mb-4">
              <label>Vorlage für 1 Tag vor Ablauf</label>
              <select class="form-select" name="" id="" v-model="activeRequestType.draft_last">
                <option :key="draft.id" :value="draft.id" v-for="draft in drafts">
                  {{ draft.title }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-2">
            <button class="btn btn-outline-secondary btn-sm me-3 w-100" :class="{'btn-success': (activeRequestType.state === 'open')}"
                    @click="activeRequestType.state = 'open'">Offen
            </button>
          </div>
          <div class="col-2">
            <button class="btn btn-outline-secondary btn-sm me-3 w-100"
                    :class="{'btn-success': (activeRequestType.state === 'closed')}"
                    @click="activeRequestType.state = 'closed'">Geschlossen
            </button>
          </div>
          <div class="col-2">
            <button class="btn btn-outline-secondary btn-sm me-3 w-100"
                    :class="{'btn-success': (activeRequestType.state === 'prepare')}"
                    @click="activeRequestType.state = 'prepare'">In Vorbereitung
            </button>
          </div>
          <div class="col-2">
            <button class="btn btn-outline-secondary btn-sm me-3 w-100"
                    :class="{'btn-success': (activeRequestType.state === 'readable')}"
                    @click="activeRequestType.state = 'readable'">Lesezugriff
            </button>
          </div>
          <div class="col-2">
            <button class="btn btn-outline-secondary btn-sm me-3 w-100"
                    :class="{'btn-success': (activeRequestType.state === 'archived')}"
                    @click="activeRequestType.state = 'archived'">Archiviert
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Willkommensnachricht</label>
              <Editor2 v-model="activeRequestType.welcome_message" :content="activeRequestType.welcome_message"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Informationen zum Lehrgang</label>
              <Editor2 v-model="activeRequestType.info_text" :content="activeRequestType.info_text"/>
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-12">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import CalcTypes from "@/routing/plugins/dfb/subs/CalcTypes.vue";
import TaskTypes from "@/routing/plugins/dfb/subs/TaskTypes.vue";

export default {
  name: "DFBRequestTypes",
  components: {
    Editor2,
    CalcTypes,
    TaskTypes
  },
  methods: {
    onImageChange() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0]; // Get the selected file
      let VueInner = this;
      if (file) {
        // Read the file as a Data URL to get base64 data
        const reader = new FileReader();
        reader.onload = () => {
          VueInner.activeRequestType.special_order_mime = file.type; // Set the image MIME type
          VueInner.activeRequestType.special_order_src = reader.result.split(',')[1]; // Set the base64 image data
        };
        reader.readAsDataURL(file);
      }
    },
    getDrafts() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=28", {headers}).then((r) => {
        this.drafts = r.data.result.data;
        this.loading = false;
      });
    },
    copyRequest(requestId, type) {



      this.$fire({
        title: 'Bewerbungsphase kopieren',
        text: 'Möchten Sie die Bewerbungsphase wirklich kopieren?',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Ja',
        cancelButtonText: 'Abbrechen',

      }).then((result) => {

       if (result && result.value === true) {

         const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
         axios.post(this.$store.state.apiUrl + "plugins/dfb/requestTypes", {
           request: requestId,
           type: type
         }, {headers}).then(() => {
           this.getRequests();
         });
       }

      })



    },
    renderGradeName(name){
      if(name ==='Torwart A Lizenz'){
        return 'TA';
      }
      if(name ==='Torwart B Lizenz'){
        return 'TB';
      }
      if(name ==='Torwart Leistungskurs'){
        return 'TL';
      }
      return name.substring(0, 2)
    },
    renderGradeClass(name) {
      const modifiedName = name
        .replace(/\+/g, "plus") // Replace + with "plus"
        .replace(/[^\w\s]/gi, "") // Remove special characters
        .replace(/\s/g, ""); // Remove spaces
      return modifiedName.substring(0, 4);
    },
    renderState(state) {
      switch (state) {
        case "open":
          return "<i class=\"fa-duotone fa-lock-keyhole-open\" style='color: #249B3A;'></i>";
        case "closed":
          return "<i class=\"fa-duotone fa-lock-keyhole\" style='color:#A80000;'></i>";
        case "prepare":
          return "<i class=\"fa-duotone fa-pen-swirl\" style='color: #0088CC;'></i>";
        case "readable":
          return "<i class=\"fa-duotone fa-book-open-reader\"></i>";
        case "archived":
          return "<i class=\"fa-duotone fa-box-archive\"></i>";
          default:
          return "<i class=\"fa-duotone fa-box-archive\"></i>";
      }
    },
    buildFilters() {

      // get full year from request types
      const years = this.requestTypes.map((r) => {
        return new Date(r.published_from).getFullYear();
      });
      this.filterYears = [...new Set(years)];

      // get full grant from request types
      const grants = this.requestTypes.map((r) => {
        return r.name;
      });
      this.filterGrants = [...new Set(grants)];

    },
    filterTypes() {

      this.filteredTypes = this.requestTypes


      if(this.filter.year !== null){
        this.filteredTypes = this.filteredTypes.filter((r) => {
          return new Date(r.published_from).getFullYear() === this.filter.year;
        });
      }

      if(this.filter.grant !== null){
        this.filteredTypes = this.filteredTypes.filter((r) => {
          return r.name === this.filter.grant;
        });
      }



    },
    getRequests() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/requestTypes", {headers}).then((r) => {
        this.requestTypes = r.data.result.data;
        this.buildFilters();
        this.filterTypes();
        this.loading = false;
      });
    },
    saveRequest() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "plugins/dfb/requestTypes", this.activeRequestType, {headers}).then(() => {
        this.getRequests();
        this.activeRequestType = null;
        this.loading = false;
      });
    },
  },
  data() {
    return {
      requests: [],
      activeRequestType: null,
      requestTypes: {},
      activeEditor: 'base',
      drafts: [],
      filterYears: [],
      filterGrants: [],
      filteredTypes: {},
      filter: {
        grant: null,
        year: new Date().getFullYear(),
      }
    };
  },
  created() {
    this.getRequests();
    this.getDrafts();
  },
  watch: {
    activeRequestType() {
      this.activeEditor = 'base';
    }
  }
};
</script>
<style lang="scss" scoped>


.product-item {
  cursor: pointer;
}

.pluginWrap .plugin-item .lineitem.l-prio {
  padding-left: 32px;
  padding-right: 32px;
}

.pluginWrap span.roundBadge {
  border: 0;
  background: #969696;
  width: 30px;
  line-height: 30px;
  height: 30px;
}
.titlelong {

    font-size: 14px;

}

</style>
